<template>
  <div class="textarea__wrapper">
    <label v-if="label" class="textarea__label">{{ label }}</label>
    <textarea
      ref="textarea"
      :value="value"
      :placeholder="placeholder"
      :rows="currentRowsCount"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
      class="textarea"
      :class="['resize-' + resize]"
    />
  </div>
</template>

<script>
export default {
  name: 'SmTextarea',

  props: {
    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      placeholder: '',
    },

    rows: {
      type: [String, Number],
      default: 1,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    maxRows: {
      type: [String, Number],
      default: 1,
    },

    resize: {
      type: String,
      default: 'none',
    },
  },

  data() {
    return {
      textareaEl: null,
      currentRowsCount: null,
      scrollHeight: null,
    };
  },

  watch: {
    value() {
      this.calcScrollHeight();
    },

    scrollHeight: {
      handler(scrollheight, oldScrollHeight) {
        this.recalcRowsCount(scrollheight, oldScrollHeight);
      },
    },
  },

  mounted() {
    this.currentRowsCount = this.rows;
    this.textareaEl = this.$refs.textarea;
    this.scrollHeight = this.textareaEl.scrollHeight;
  },

  methods: {
    calcScrollHeight() {
      this.scrollHeight = this.textareaEl.scrollHeight;
    },

    recalcRowsCount(scrollheight, oldScrollHeight) {
      if (
        oldScrollHeight < scrollheight &&
        oldScrollHeight &&
        this.currentRowsCount < this.maxRows
      ) {
        this.currentRowsCount += 1;
      } else if (
        oldScrollHeight > scrollheight &&
        oldScrollHeight &&
        this.currentRowsCount > this.rows
      ) {
        this.currentRowsCount -= 1;
      }
    },
  },
};
</script>

<style lang="scss">
.textarea__wrapper {
  position: relative;

  width: 100%;
}

.textarea__label {
  display: inline-block;
  margin-bottom: 3px;

  color: var(--gray);
}

.textarea {
  width: 100%;
  min-height: 36px;
  max-height: 100%;
  padding: 7px 12px;

  font-size: 16px;
  line-height: 1;

  border: 1px solid var(--gray);
  border-radius: 10px;

  outline: none;
  scrollbar-color: var(--gray) var(--white);
  scrollbar-width: thin;
}

.textarea::-webkit-scrollbar-track {
  margin: 10px 0;

  background-color: transparent;
}

.textarea::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--gray);
}

.textarea::-webkit-scrollbar {
  width: 3px;
}

.textarea::placeholder,
.textarea::-webkit-input-placeholder {
  /* font-weight: 300; */
  color: var(--gray);
}

.textarea:disabled {
  background-color: rgba(var(--rgb-gray), 0.2);

  cursor: not-allowed;
}

.resize-none {
  resize: none;
}

.resize-vertical {
  resize: vertical;
  overflow: hidden;
}
</style>
